.signup {
  background: white;

  .signup-card {
    background: whitesmoke;
    max-width: 1000px;
    border-radius: 12px !important;
    // img {
    //   width: 300px;
    // }
  }

  .signup-content {
    position: relative;
    // left: 53.19%;
    // right: 23.47%;
    // top: 51.56%;
    // bottom: 48.24%;

    border: 2px solid #fbfbfb;
  }
}
